import endpoint from "@/api/endpoint";

export default {

    store(data) {
        return window.axios.post(endpoint + '/api/materials', data);
    },

    item(data) {
        return window.axios.get(endpoint + '/api/materials/' + data.id);
    },

    auth_item(data) {
        return window.axios.get(endpoint + '/api/auth_materials/' + data.id);
    },

    update(data) {
        return window.axios.post(endpoint + '/api/materials/' + data.id, data);
    },

}
